<template>
    <div>
        <page-header
            v-show="showClose"
            :show-close="true"
            @onClose="$router.go(-1)"
        />
        <div v-if="isUnrecoverableError">
            <div>
                <error-view
                    :image-path-in-assets="'support.svg'"
                    :show-title="true"
                    :show-description="false"
                    :title="unrecoverableActivationError"
                    :cta="buttonCta"
                    @click="callSupport"
                />
            </div>
        </div>
        <div
            v-else
            class="container"
        >
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <div
                        v-if="!cardRequiresActivation"
                        class="text-center mt-4"
                    >
                        <img
                            v-if="showClose"
                            src="../assets/images/global/aven.svg"
                            width="71"
                            class="mb-5"
                            alt=""
                        >
                        <div>
                            <img
                                class="mb-2"
                                src="@/assets/images/pages/settings/cardActivationSuccessful.svg"
                                width="200"
                                :alt="$t('pages.CardActivation.activationSuccessful.imgAlt')"
                            >
                            <h5
                                class="mb-4"
                                v-html="$t('pages.CardActivation.activationSuccessful.descTitle')"
                            />

                            <base-button
                                type="submit"
                                @click="clickedLetsGo"
                            >
                                {{ $t('pages.CardActivation.activationSuccessful.letsGo') }}
                            </base-button>
                        </div>
                    </div>
                    <div
                        v-else
                        class="text-center"
                    >
                        <img
                            v-if="!showClose"
                            src="../assets/images/global/aven.svg"
                            width="71"
                            class="mb-5"
                            alt=""
                        >
                        <div>
                            <h5
                                class="mb-2"
                                v-html="$t('pages.CardActivation.descTitle')"
                            />
                            <form-container
                                ref="phoneForm"
                                @onSubmit="activateCreditCard"
                            >
                                <form-field
                                    ref="last4DigitsAvenCard"
                                    name="last4DigitsAvenCard"
                                    input-type="tel"
                                    inputmode="decimal"
                                    :max-length="4"
                                    validation="required|numeric|min:4|max:4"
                                    v-model="last4DigitsAvenCard"
                                    :placeholder="$t('pages.CardActivation.Last4ofCardNumber')"
                                    class="mb-2"
                                />
                                <base-button
                                    type="submit"
                                    :submitting="loading"
                                >
                                    {{ $t('pages.CardActivation.continue') }}
                                </base-button>
                            </form-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import BaseButton from "@/components/base/BaseButton";
    import FormContainer from "@/components/base/FormContainer";
    import PageHeader from "@/components/PageHeader";
    import FormField from "@/components/base/FormField";
    import {i18n} from "@/utils/i18n";
    import {contact_info} from "@/utils/contact-info-dictionary";
    import ErrorView from "@/components/ErrorView";
    import {isMobile} from "@/utils/parseUserAgents";
    import {mapGetters, mapState} from "vuex";
    import {CardActivationError, NETWORK_ERROR, SERVER_RESPONSE_ERROR} from "@/store";
    import {ApiErrorHandler} from "@/utils/exception-handler";
    import { NYC_TIMEZONE } from "@/data/constants";
    import generic from "@/utils/generic";
    import { postLoginNavigation } from "@/utils/sharedLogic";
    import {logger} from "@/utils/logger";
    import { hideNativeNavBar, showNativeNavBar } from '@/utils/mobileUtils'

    const RESCISSION_DATE_FORMAT = 'MMM D, YYYY hh:mm A z'

    export default {
        components: {
            FormField,
            BaseButton,
            FormContainer,
            PageHeader,
            ErrorView,
        },
        data() {
            return {
                last4DigitsAvenCard: "",
            };
        },
        computed: {
            ...mapState({
                isUnrecoverableError(state) {
                    return state.error === CardActivationError.CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED || state.error === CardActivationError.ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED
                },
                errorPayload: 'errorPayload',
            }),
            ...mapGetters(['loading', 'cardRequiresActivation', 'error', 'isSingleWebView']),
            showClose() {
                return this.cardRequiresActivation
            },
            unrecoverableActivationError() {
                return isMobile() ?
                    i18n.t('pages.CardActivation.unrecoverableActivationError.description') :
                    i18n.t('pages.CardActivation.unrecoverableActivationError.descriptionNonMobile')
            },
            buttonCta() {
                return isMobile() ? i18n.t('pages.CardActivation.unrecoverableActivationError.buttonText') : null
            },
        },
        watch: {
            error(e) {
                if (e === CardActivationError.ERROR_IN_RESCISSION_PERIOD) {
                    const formattedDate = generic.utcToTimeZone(this.errorPayload.rescissionEndDate, NYC_TIMEZONE, RESCISSION_DATE_FORMAT)
                    const message = i18n.t(`pages.CardActivation.${e}`, {date: formattedDate})
                    this.applyGeneralError(message)
                } else if (e === CardActivationError.ERROR_BLOCKED_BY_PENDING_CONTINGENCIES) {
                    const message = i18n.t(`pages.CardActivation.ERROR_BLOCKED_BY_PENDING_CONTINGENCIES`)
                    this.applyGeneralError(message)
                } else if (e === CardActivationError.ERROR_CARD_ACTIVATION_DATA_INVALID) {
                    const message = i18n.t(`pages.CardActivation.${e}`, {last4Digits: this.last4DigitsAvenCard})
                    this.applyGeneralError(message)
                } else if (e === CardActivationError.ERROR_PRIMARY_CARD_NOT_ACTIVATED) {
                    this.applyGeneralError(i18n.t(`pages.CardActivation.${e}`))
                } else if (e === SERVER_RESPONSE_ERROR){
                    this.applyGeneralError(i18n.t(`global.errors.serverError`))
                } else if (e === NETWORK_ERROR ) {
                    this.applyGeneralError(i18n.t(`global.errors.generic`))
                }
            }
        },
        mounted() {
            hideNativeNavBar()
        },
        destroyed() {
            showNativeNavBar()
        },
        methods: {
            activateCreditCard: async function() {
                const isValid = await this.$refs.phoneForm.$refs.observer.validate()
                logger.info(`card number input is ${isValid}`)
                if (!isValid) {
                    return
                }
                try {
                    await this.$store.dispatch('activateCard', this.last4DigitsAvenCard)
                    this.$logEvent('credit_card_activated')
                } catch(error){
                    this.$logEvent('card_activation_failed')
                    ApiErrorHandler(error)
                }
            },
            clickedLetsGo: async function() {
                await postLoginNavigation()
            },
            applyGeneralError (errorMessage){
                this.$refs.phoneForm.applyGeneralError(errorMessage)
            },
            callSupport() {
                window.location.href = `tel: ${contact_info.phone}`
            },
        }
    };
</script>
